@use 'layout-variables' as *;

// Material panels don't allow custom max-widths, which conflicts with design
.mat-mdc-menu-panel {
	max-width: unset !important;

	.mat-mdc-menu-content:not(:empty) {
		padding-top: 0;
		padding-bottom: 0;
	}
}

// The country panel is not a child of any component and is found directly under root (as a material panel)
.country-panel {
	overflow-x: hidden !important;
	max-height: 80 * $spacing-base-value !important;
	width: 100 * $spacing-base-value !important;

	.mat-mdc-menu-content {
		padding: 0;

		.country-search {
			padding: $spacing-s (5 * $spacing-base-value);
			height: inherit;
			font-size: inherit;

			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 100;
			background: white;

			&:focus-visible {
				outline: inherit;
			}
		}
	}
}
