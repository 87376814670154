/** Critical CSS that should be loaded before everything else */

.container-controller {
	height: 100%;
}

.ar-controller {
	display: flex;
	position: relative;
	width: 100%;

	&__sizer {
		margin-top: 100%;
	}

	&__container {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.full-width {
	width: 100%;
}
