@use 'layout' as *;
@use 'layout-variables' as *;
@use 'generic-variables' as *;

.dialog-backdrop {
	background: rgba(15, 15, 15, 0.28);
}

.message-panel {
	.mat-mdc-dialog-container {
		border-radius: 2px;
		padding: 0;
		display: flex;
		justify-content: center;
		overflow: visible;

		.mat-mdc-dialog-surface {
			overflow: visible;
			border-radius: 0;
		}
	}

	@include media('>phone') {
		max-height: calc(100vh - (2 * $spacing-xl));
	}

	@include media('<=phone') {
		position: fixed !important;
		bottom: 0;
		left: 0;
		margin-bottom: 0 !important;
		max-width: unset !important;
		width: 100%;
		max-height: calc(100vh - $spacing-xxxl);
	}
}

.message-panel.crap-panel {
	mk-modal-container {
		max-width: $spacing-base-value * 168;

		.actions {
			@include media('>phone') {
				max-width: 480px;
			}
		}
	}
}

.adyen-panel,
.mollie-panel {
	@extend .message-panel !optional;

	@include media('<=phone') {
		width: 100%;
	}
}

.teaser-panel {
	.mat-mdc-dialog-container {
		.mat-mdc-dialog-surface {
			border-radius: 0;
		}
	}
}
