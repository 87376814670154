@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'mixins/custom-typography' as *;

@mixin styled-table() {
	@include custom-typography();

	table {
		border: unset;
		margin: unset;
		border-collapse: collapse;
		width: 100%;

		tr {
			border-bottom: 1px solid map-get($md-grey, 500);

			&:first-child {
				border-top: 1px solid map-get($md-grey, 500);
			}
		}

		th,
		td {
			padding: ($spacing-xs + 2) $spacing-xxs;
			text-align: left;
			font-size: inherit;
		}

		td {
			@extend .mat-body-large !optional;
			color: map-get($md-grey, 600);
		}

		th,
		strong {
			@extend .mat-body-1-xbold !optional;
			color: map-get($palette-primary, 500);
			// This is necessary as somehow the bold font-weight gets overriden by the default font weight of mat-body-1
			font-weight: $bold-heavy !important;
		}
	}
}
