@use '@angular/material' as mat;
@use 'layout' as *;
@use 'functions/create-typography-config-sizes' as *;
@use 'typography-variables' as *;

@mixin bold-heavy() {
	font-weight: $bold-heavy;
}

@mixin bold() {
	font-weight: $bold-light;
}

strong {
	@include bold-heavy();
}

@include mat.core();
@include mat.core();

@include mat.all-component-typographies(
	create-typography-config-sizes(
		$font-headline-1,
		$font-headline-2,
		$font-headline-3,
		$font-headline-4,
		$font-headline-5,
		$font-headline-6,
		$font-subtitle-1,
		$font-subtitle-2,
		$font-body-1,
		$font-body-2,
		$font-caption,
		$font-button
	)
);

@include media('<=tablet') {
	@include mat.all-component-typographies(
		create-typography-config-sizes(
			$font-headline-1-tablet,
			$font-headline-2-tablet,
			$font-headline-3-tablet,
			$font-headline-4-tablet,
			$font-headline-5-tablet,
			$font-headline-6-tablet,
			$font-subtitle-1-tablet,
			$font-subtitle-2-tablet,
			$font-body-1-tablet,
			$font-body-2-tablet,
			$font-caption-tablet,
			$font-button-tablet
		)
	);
}

@include media('<=phone') {
	@include mat.all-component-typographies(
		create-typography-config-sizes(
			$font-headline-1-mobile,
			$font-headline-2-mobile,
			$font-headline-3-mobile,
			$font-headline-4-mobile,
			$font-headline-5-mobile,
			$font-headline-6-mobile,
			$font-subtitle-1-mobile,
			$font-subtitle-2-mobile,
			$font-body-1-mobile,
			$font-body-2-mobile,
			$font-caption-mobile,
			$font-button-mobile
		)
	);
}

.mat-typography {
	font-size: $font-body-1;

	.mat-headline-6 {
		@extend .mat-headline6 !optional;
	}
}

$fonts: create-typography-config-sizes(
	$font-headline-1,
	$font-headline-2,
	$font-headline-3,
	$font-headline-4,
	$font-headline-5,
	$font-headline-6,
	$font-subtitle-1,
	$font-subtitle-2,
	$font-body-1,
	$font-body-2,
	$font-caption,
	$font-button
);
