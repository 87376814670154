// This file was created so that we can import global variables without re-importing the whole theming library - this will make the bundle smaller

// Font families
$font-helvetica-now-var: HelveticaNowVar, Helvetica, Arial, sans-serif;

// Sizing
$font-xxxxl: 96px;
$font-xxl: 40px;
$font-xl: 28px;
$font-l: 22px;
$font-m: 20px;
$font-s: 18px;
$font-xs: 16px;
$font-xxs: 14px;
$font-xxxs: 10px;
$font-xxxl: 12px;
$font-xxxm: 11px;
$font-xxxxs: 9px;
$font-xxxxxs: 8px;

// Typography configuration specific sizing
$font-headline-1: 64px;
$font-headline-2: 48px;
$font-headline-3: 30px;
$font-headline-4: 24px;
$font-headline-5: $font-m;
$font-headline-6: $font-s;
$font-subtitle-1: $font-xs;
$font-subtitle-2: $font-xxs;
$font-body-1: $font-xxs;
$font-body-2: $font-xxxl;
$font-caption: $font-xxxs;
$font-button: $font-xxs;
$font-body-large: $font-xs;
$font-micro-10: $font-xxxs;
$font-micro-8: $font-xxxxxs;

// Tablet
$font-headline-1-tablet: 56px;
$font-headline-2-tablet: 44px;
$font-headline-3-tablet: 26px;
$font-headline-4-tablet: $font-m;
$font-headline-5-tablet: $font-s;
$font-headline-6-tablet: $font-s;
$font-subtitle-1-tablet: $font-xxs;
$font-subtitle-2-tablet: $font-xxxl;
$font-body-1-tablet: $font-xxs;
$font-body-2-tablet: $font-xxxl;
$font-caption-tablet: $font-xxxs;
$font-button-tablet: $font-xxs;

// Mobile
$font-headline-1-mobile: 46px;
$font-headline-2-mobile: 34px;
$font-headline-3-mobile: 24px;
$font-headline-4-mobile: $font-m;
$font-headline-5-mobile: $font-s;
$font-headline-6-mobile: $font-xs;
$font-subtitle-1-mobile: $font-xxs;
$font-subtitle-2-mobile: $font-xxxl;
$font-body-1-mobile: $font-xxs;
$font-body-2-mobile: $font-xxxl;
$font-caption-mobile: $font-xxxs;
$font-button-mobile: $font-xxs;

// Letter Spacing
$letter-spacing-xxl: 1.8px;
$letter-spacing-xl: 1.5px;
$letter-spacing-l: 1.2px; //unused, added for consistency
$letter-spacing-m: 1px; //unused, added for consistency
$letter-spacing-s: 0.8px;
$letter-spacing-xs: 0.5px;
$letter-spacing-xxs: 0.4px;
$letter-spacing-xxxs: 0.25px;
$letter-spacing-xxxxs: 0.22px;
$letter-spacing-xxxxxs: 0.18px;
$letter-spacing-xxxxxxs: 0.12px;

// Line height
$line-height-heading: 1.2;
$line-height-base: 1.4;

$line-height-xs: 100%;
$line-height-s: 112%;
$line-height-m: 120%;
$line-height-l: 140%;
$line-height-xl: 150%;
$line-height-xxl: 160%;

// Font weights
$bold-heavy: 800;
$bold-light: 500;
