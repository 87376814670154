@use 'palette' as *;
@use 'layout' as *;
@use 'typography' as *;
@use 'layout-variables' as *;
@use 'mat-override' as *;
@use 'mixins/custom-typography' as *;
@use 'components/browser-warning' as *;
@use 'fonts' as *;

@import 'include-media/dist/_include-media.scss';
@import 'node_modules/@angular/material/prebuilt-themes/indigo-pink.css';

// removes global added by material
body {
	margin: 0;
	padding: 0;
}

html {
	scroll-behavior: auto;
	overflow-y: scroll;

	&.browser-firefox {
		@include media('>tablet-landscape') {
			&.cdk-global-scrollblock {
				position: static !important;
				overflow-y: scroll !important;
			}
		}
	}

	.mat-pseudo-checkbox-full {
		color: #0f0f0f;
	}

	--mdc-filled-text-field-label-text-weight: 400;
	--mat-form-field-container-text-weight: 400;

	.mat-mdc-tooltip .mdc-tooltip__surface {
		--mdc-plain-tooltip-container-color: #0f0f0f;
		--mdc-plain-tooltip-supporting-text-size: 12px;

		padding: 0;
	}
}

.mat-body-1,
.mat-headline-4,
.mat-headline-3,
.mat-headline-2,
.mat-headline-1,
.mat-headline-6,
.mat-subtitle-2,
.mat-subtitle-1,
.mat-mdc-button,
.mat-input {
	color: map-get($md-grey, 900);
}

.mat-caption {
	color: map-get($md-grey, 500);
}

a.unstyled-link {
	display: block;
	text-decoration: none;
	outline: none;
}

.hyphenate {
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}

.grecaptcha-badge.visible {
	z-index: 99;
}

mat-spinner.hidden * {
	animation: none !important;
}

.hidden mat-spinner * {
	animation: none !important;
}

// fix chrome autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
}

// Custom font styles are imported here, so we can use them on material's components outside of the root component
@include custom-typography();

// Fix for storybook. Otherwise Material overrides our custom styles
.sb-show-main {
	@include custom-typography();
}

button {
	color: inherit;
	font-family: HelveticaNowVar;
}

.chunkLoadErrorContainer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: white;
	z-index: 9999;
	padding: $spacing-sm;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	box-sizing: border-box;

	h1 {
		font-size: $font-headline-2;
	}

	p {
		margin-bottom: 5 * $spacing-xxs;
	}

	img {
		width: 240px;
		margin-bottom: 5 * $spacing-xxs;
	}

	button {
		background: black;
		color: white;
		text-transform: uppercase;
		border: none;
		line-height: 1;
		cursor: pointer;
		font-weight: bold;
		padding: $spacing-s $spacing-sm;
		width: 304px;
		height: 6 * $spacing-xxs;
	}
}

.panel-fit-content.mat-mdc-select-panel {
	min-width: fit-content;
}
.mat-mdc-option.mdc-list-item {
	.more-option {
		text-wrap: nowrap;
	}
}

@import 'lightgallery/scss/lightgallery';
@import 'lightgallery/scss/lg-zoom';
