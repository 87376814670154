@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'typography' as *;
@use 'layout' as *;
@use 'mixins/custom-typography' as *;

@include custom-typography();

mat-tooltip-component {
	.mat-mdc-tooltip {
		background-color: map-get($palette-primary, 500);
		border: 0.4px solid map-get($palette-primary, 100);
		border-radius: 2px;
		padding: $spacing-xxxs (1.5 * $spacing-xxxs);
		margin: $spacing-xxs $spacing-xxxs;
		max-width: 230px;
		@extend .mat-body-2 !optional;
	}
}
