@use 'layout' as *;
@use 'typography-variables' as *;
@forward 'typography-variables';

@mixin custom-typography() {
	// Completely custom typographies which don't fit angular material's theming options

	.mat-headline-6-medium {
		@extend .mat-headline-6 !optional;
		font-weight: $bold-light;
		line-height: $line-height-l;
	}

	.mat-subtitle-2-uppercase {
		@extend .mat-subtitle-2 !optional;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-xxs;
	}

	.mat-body-large {
		font-family: $font-helvetica-now-var;
		font-size: $font-xs;
		font-weight: 400;
		line-height: $line-height-l;

		@include media('<=phone') {
			font-size: $font-xxs;
		}
	}

	.mat-body-large-xbold {
		@extend .mat-body-large !optional;
		font-weight: $bold-heavy;
	}

	.mat-body-large-uppercase {
		@extend .mat-body-large !optional;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-xxs;
	}

	.mat-body-large-underline {
		@extend .mat-body-large !optional;
		text-decoration: underline;
	}

	.mat-body-1-underline {
		@extend .mat-body-1 !optional;
		text-decoration: underline;
	}

	.mat-body-1-xbold {
		@extend .mat-body-1 !optional;
		font-weight: $bold-heavy;
	}

	.mat-body-1-xbold-underline {
		@extend .mat-body-1 !optional;
		font-weight: $bold-heavy;
		text-decoration: underline;
	}

	.mat-body-2-underline {
		@extend .mat-body-2 !optional;
		text-decoration: underline;
	}

	.mat-body-2-xbold {
		@extend .mat-body-2 !optional;
		font-weight: $bold-heavy;
	}

	.mat-body-2-uppercase {
		@extend .mat-body-2 !optional;
		text-transform: uppercase;
		letter-spacing: $letter-spacing-xxs;
	}

	.mat-body-2-xbold-uppercase {
		@extend .mat-body-2-uppercase !optional;
		font-weight: $bold-heavy;
	}

	.mat-caption-auto {
		@extend .mat-caption !optional;
		line-height: normal;
	}

	.mat-caption-xbold {
		@extend .mat-caption !optional;
		font-weight: $bold-heavy;
	}

	.mat-micro-10 {
		font-family: $font-helvetica-now-var;
		font-size: $font-xxxs;
		font-weight: 400;
		line-height: $line-height-xxl;
		font-variation-settings: 'opsz' 4 !important;
	}

	.mat-micro-10-medium {
		@extend .mat-micro-10 !optional;
		font-weight: $bold-light;
	}

	.mat-micro-10-medium-uppercase {
		@extend .mat-micro-10-medium !optional;
		text-transform: uppercase;
	}

	.mat-micro-10-xbold-uppercase {
		@extend .mat-micro-10 !optional;
		font-weight: $bold-heavy;
		text-transform: uppercase;
	}

	.mat-micro-10-xbold {
		@extend .mat-micro-10 !optional;
		font-weight: $bold-heavy;
	}

	.mat-micro-8-medium {
		@extend .mat-micro-10-medium !optional;
		font-size: $font-xxxxxs;
	}

	.mat-mdc-button,
	.mat-custom-button {
		@extend .mat-mdc-button !optional;
		line-height: $line-height-m;
		white-space: normal;
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	.text-underline {
		text-decoration: underline;
	}

	.text-feature-numbers {
		font-feature-settings: 'lnum', 'tnum' !important;
	}

	@include media('<=phone') {
		.mat-headline-3 {
			line-height: $line-height-m !important;
		}

		.mat-caption-xbold {
			line-height: $line-height-m;
		}
	}

	// Font optical sizes
	.mat-headline-1,
	.mat-headline-2,
	.mat-headline-3,
	.mat-headline-4,
	.mat-headline {
		font-variation-settings: 'opsz' 30 !important;
	}

	h2,
	h3,
	h4,
	.mat-headline-5,
	.mat-headline-6,
	.mat-subtitle-1,
	.mat-body-1,
	.mat-subtitle-2,
	.mat-body-2-xbold,
	.mat-custom-button,
	.mat-mdc-button,
	.mat-body-2,
	.mat-body-large-medium,
	.mat-body-large-xbold,
	.mat-body-2,
	.mat-caption {
		font-variation-settings: 'opsz' 12 !important;
	}

	.mat-typography,
	.mat-headline-1,
	.mat-headline-2,
	.mat-headline-3,
	.mat-headline-4,
	.mat-headline-5,
	.mat-headline-6,
	.mat-subtitle-1,
	.mat-subtitle-2,
	.mat-body-1,
	.mat-body-2,
	.mat-caption,
	.mat-button {
		font-feature-settings: inherit;
	}
}
