@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'mixins/table' as *;

// Tables from sulu are wrapped in a figure.table tag
.table,
.table-container {
	margin: 0;
	overflow-x: auto;

	@include styled-table();

	&-continued {
		table tr:first-child {
			border-top: 0;
		}
	}
}
