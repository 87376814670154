@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'layout' as *;

.mat-mdc-snack-bar-container {
	--mdc-snackbar-container-color: transparent;
}

// Toast style overrides
body {
	.cdk-overlay-pane:not(.message-panel) {
		.mat-mdc-snack-bar-container {
			position: absolute !important;
			left: $spacing-sm;
			bottom: $spacing-sm;
			right: unset;
			margin: 0;

			@include media('<=phone') {
				left: 0;
				bottom: $spacing-s;
				max-width: none;
				width: calc(100% - $spacing-s * 2);
				margin: 0 $spacing-s;
			}
		}
	}
}

.toast {
	&-success {
		background-color: map-get($md-blue, 500);
	}

	&-error {
		background-color: map-get($md-red-light, 500);
	}

	&-info {
		background-color: map-get($md-grey, 900);
		box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
		border: solid 0.4px map-get($md-grey, 600);
	}

	&-warning {
		background-color: map-get($md-orange, 500);
	}

	&-global {
		&.mat-mdc-snack-bar-container {
			min-width: $spacing-base-value * 90;
			padding: 0;
			border-radius: 4px;

			.mdc-snackbar__surface {
				min-width: $spacing-base-value * 90;
			}

			@include media('<=tablet') {
				margin-bottom: 0;
			}

			@include media('<=phone') {
				min-width: auto;

				.mdc-snackbar__surface {
					min-width: auto;
				}
			}
		}
	}
}
