@use 'layout-variables' as *;
@use 'include-media' as *;

$breakpoints: (
	phone-xs: 400px,
	phone: 650px,
	phone-xl: 768px,
	tablet: 968px,
	tablet-landscape: 1024px,
	// special breakpoint for teaser builder portrait image
	teaser-builder: 1178px,
	desktop: 1440px,
	desktop-xl: 1600px,
	desktop-xxl: 2000px,
);
@forward 'include-media';

$app-content-size: 1468px;
$desktop-content-spacing: $spacing-m;
$mobile-content-spacing: $spacing-s;
$app-content-touch-breakpoint: $app-content-size + $desktop-content-spacing * 2;

.app-content {
	max-width: $app-content-size;
	margin: 0 auto;
	padding: 0 $desktop-content-spacing;

	@include media('<=phone') {
		padding: 0 $mobile-content-spacing;
	}

	&-full {
		max-width: none;
	}
}

.outgrow-app-content {
	max-width: none;
	margin: 0 -#{$desktop-content-spacing};

	@include media('<=phone') {
		margin: 0 -#{$mobile-content-spacing};
	}
}

.row {
	display: flex;

	&-center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&-v-center {
		display: flex;
		align-items: center;
	}

	&-h-center {
		display: flex;
		justify-content: center;
	}

	&-baseline {
		display: flex;
		align-items: baseline;
	}
}

.column {
	display: flex;
	flex-direction: column;
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.no-margin {
	margin: 0 !important;
}

/**
    .no-(phone, tablet, desktop) -> exclusively hide on X
    .phone, .tablet, .desktop -> exclusively show on X
 */

@include media('>tablet') {
	.no-desktop,
	.tablet,
	.phone {
		display: none !important;
	}
}

@include media('>phone', '<=tablet') {
	.no-tablet,
	.desktop,
	.phone {
		display: none !important;
	}
}

@include media('<=phone') {
	.no-phone,
	.tablet,
	.desktop {
		display: none !important;
	}
}
