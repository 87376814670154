@use 'typography-variables' as *;
@use 'typography' as *;
@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'layout' as *;
@use 'mixins/page' as *;

html {
	--mdc-filled-text-field-container-color: transparent;
	--mat-form-field-filled-with-label-container-padding-top: 30px;
}

mat-form-field {
	@media not all and (min-resolution: 0.001dpcm) {
		@supports (-webkit-appearance: none) and (stroke-color: transparent) {
			input[type='password'] {
				font-family: sans-serif;
			}
		}
	}

	/**
	These are custom adjustments to match dropdowns and autocomplete inputs and phone input to mk-input fields.
	The difference in the spacing is coming from a few places:
	 - legacy form-field module doesn't seem to match spacing for inputs and dropdowns
	 - autocomplete doesn't have legacy component support
	 - phone input library uses latest material components which are different than the legacy ones

	 Hopefully to be removed with next material update
	**/

	&.mat-form-field-appearance-fill {
		.mdc-text-field--filled {
			padding-left: 0;
			padding-right: 0;
		}

		.mat-mdc-form-field-hint-wrapper,
		.mat-mdc-form-field-error-wrapper {
			padding: 0;
		}

		.mat-mdc-form-field-focus-overlay {
			background-color: transparent;
		}

		.mat-mdc-text-field-wrapper {
			&:not(.mdc-text-field--outlined) {
				.mat-mdc-form-field-infix {
					padding-top: 30px;
				}
			}
		}

		.mat-mdc-form-field-infix {
			padding-top: 30px;
		}

		.mdc-floating-label {
			&:not(.mdc-floating-label--float-above) {
				transform: none;
			}
		}

		.mdc-floating-label--required {
			&:not(.mdc-floating-label--hide-required-marker) {
				font-size: 0;
				color: inherit !important;

				&:after {
					content: '°';
					display: inline-block;
					margin-left: $spacing-xxxs;
					font-size: $font-xxs;
				}
			}
		}
	}
}

mat-option.mat-mdc-option {
	height: 57px;
	position: relative;
	z-index: 5;

	@include media('<=phone') {
		padding: 0 $spacing-xs;
	}

	.mdc-list-item__primary-text {
		width: 100%;
	}
}

.mat-select-panel-wrap {
	.mat-mdc-select-panel {
		border-radius: $spacing-base-value - 2;
	}
}

.cdk-overlay-pane {
	background: white;
}

.mat-mdc-select-panel {
	max-height: $spacing-base-value * 100;
	box-shadow:
		0 2px 4px -1px #0003,
		0 4px 5px #00000024,
		0 1px 10px #0000001f;

	.mat-mdc-option.mat-selected:not(.mat-mdc-option-multiple) {
		background: map-get($md-grey, 200);
	}
}

mat-select {
	.mat-mdc-select-arrow-wrapper {
		height: auto;

		.mat-mdc-select-arrow {
			@include chevron($spacing-xxs, 1px, 45deg, map-get($palette-primary, 500));

			top: $spacing-xxxs;
			max-width: none;
			margin-right: $spacing-xxxs - 2;

			svg {
				display: none;
			}
		}
	}
}

// Vertical center for dropdowns without a label
mat-form-field.v-center-select {
	.mat-mdc-text-field-wrapper {
		border-radius: 0;

		> .mat-mdc-form-field-flex {
			border-radius: 0;
			padding-top: 0;
			padding-left: $spacing-xs;
			padding-right: $spacing-s;
			background: map-get($md-grey, 50);

			> .mat-mdc-form-field-infix {
				border: 0;
				padding: ($spacing-s + $spacing-xxxs) 0;
			}
		}
	}

	.mat-mdc-select-arrow-wrapper {
		transform: inherit !important;
	}

	mat-select .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
		top: -2px;
	}

	&.mat-form-field-invalid {
		.mat-mdc-form-field-flex {
			background: rgba(map-get($md-red-light, 500), 0.1);
		}

		mat-select {
			.mat-mdc-select-placeholder {
				color: map-get($md-red, 500);
			}
		}
	}
}

.h-center-select {
	.mat-mdc-select-value {
		text-align: center;
	}
}

.mat-mdc-option:not(:disabled):hover {
	background: map-get($md-grey, 100);
}

mat-option.country-not-available {
	&.first {
		border-top: 1px solid map-get($palette-primary, 50);
	}
	&.other-country.mat-option-disabled:hover {
		cursor: pointer;

		&:hover {
			background: map-get($md-grey, 100);
		}
	}
	.mdc-list-item__primary-text {
		display: flex;
		justify-content: space-between;
		align-items: center;

		span {
			opacity: 0.6;
			color: map-get($palette-primary, 500);
		}

		img {
			height: $spacing-xs;
			filter: grayscale(1);
			margin-right: $spacing-xxs;
		}
	}
}

.country-warning-icon {
	position: absolute;
	right: 2 * $spacing-xs;
	height: $spacing-xs;
	margin: $spacing-xxxs 0;
	z-index: 10;
}
