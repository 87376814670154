@use 'layout-variables' as *;
@use 'palette-variables' as *;
@use 'typography-variables' as *;
@forward 'layout-variables';

@mixin divider() {
	.divider {
		height: 1px;
		border-bottom: solid 0.2px map-get($md-grey, 300);
		margin-bottom: $spacing-xs;
	}
}

@mixin tag($color) {
	background-color: $color;
	font-weight: 800;
	color: white;
	font-size: $font-xxxs;
	line-height: 1.73;
	padding: 0 $spacing-xxs;
	margin-right: $spacing-xs + $spacing-xxxs;
}

@mixin text-ellipsis($width: 100%) {
	display: inline-block;
	max-width: $width;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin text-ellipsis-lines($numberOfLines: 2) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: $numberOfLines;
	line-clear: $numberOfLines;
	-webkit-box-orient: vertical;
}

@mixin dropdown-chevron($size, $rotation) {
	transform: rotate($rotation);
	display: block;
	background-image: url(/de/assets/images/icons/actions/angle-down-2.svg?v=2) !important;
	background-repeat: no-repeat;
	background-size: $size;
	width: $size;
	height: $size;
}

@mixin chevron($size, $border-width, $rotation, $color) {
	transform: rotate($rotation);
	display: inline-block;
	width: $size;
	height: $size;
	border-style: solid;
	border-width: 0 $border-width $border-width 0;
	border-color: $color;
	box-sizing: border-box;
}

@mixin plus-icon() {
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: $spacing-xs;
		height: $spacing-xxxs - 2;
		background: #000;
	}

	&:after {
		transform: translateY(-50%) rotate(90deg);
	}
}

@mixin line-clamp($lines: 2) {
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	line-clamp: $lines;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
}

@mixin absolute-stretch() {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
