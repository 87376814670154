$spacing-base-value: 4px;

$spacing-xxxs: $spacing-base-value; // 4px
$spacing-xxs: $spacing-base-value * 2; // 8px
$spacing-xs: $spacing-base-value * 3; // 12px
$spacing-s: $spacing-base-value * 4; // 16px
$spacing-sm: $spacing-base-value * 6; // 24px
$spacing-m: $spacing-base-value * 8; // 32px
$spacing-l: $spacing-base-value * 12; // 48px
$spacing-xl: $spacing-base-value * 15; // 60px
$spacing-xxxl: $spacing-base-value * 20; // 80px
