@use 'layout' as *;
@use 'layout-variables' as *;
@use 'generic-variables' as *;

mk-moped-manufacturer-detail,
mk-parts-manufacturer-detail {
	mk-manufacturer-intro,
	.header-image,
	.blocks > * {
		@include media('>tablet') {
			margin-bottom: $spacing-xxxl;
		}

		@include media('<=tablet') {
			margin-bottom: $spacing-l;
		}
	}

	mk-text-with-image,
	mk-text-block {
		margin-bottom: 0;
	}

	mk-small-container {
		mk-text-block {
			max-width: $text-max-width-s !important;

			@include media('<=tablet') {
				margin: auto;
			}
		}

		p.intro {
			max-width: $text-intro-max-width;
		}
	}

	.container-slider {
		position: relative;

		@include media('>desktop') {
			overflow: hidden;
		}
	}

	.container-small {
		display: block;
		max-width: $spacing-base-value * 212;
	}

	.full-width-desktop {
		@include media('<=desktop') {
			max-width: none;
		}
	}
}
