@use 'palette-variables' as *;
@use 'layout' as *;
@use 'layout-variables' as *;
@use 'mixins/custom-typography' as *;
@use 'typography-variables' as *;

@include custom-typography();

.browser-warning {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 20;
	padding: $spacing-sm $spacing-s;
	background: map-get($md-sand, 100);
	text-align: center;

	&:not([hidden]) {
		animation: forwards appear 500ms;
	}

	p {
		@extend .mat-subtitle-2 !optional;
		margin: 0;
		color: map-get($palette-primary, 500);

		strong {
			@extend .mat-body-large-xbold !optional;
			font-weight: 600;
		}
	}

	.actions {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: $spacing-s;
		margin-top: $spacing-sm;
	}

	.btn {
		@extend .mat-subtitle-2 !optional;

		display: inline-flex;
		justify-content: center;
		align-items: center;
		height: $spacing-base-value * 14;
		margin: 0;
		border: 0;
		border-radius: 0;
		padding: 0 $spacing-sm;
		font-size: $font-subtitle-2;
		font-weight: $bold-heavy;
		text-transform: uppercase;
		text-decoration: none;
		box-sizing: border-box;
		box-shadow: none;
		cursor: pointer;
	}

	a {
		background: map-get($palette-primary, 500);
		color: white;
	}

	button {
		background: none;
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 100%;
	}
}
