@use 'layout-variables' as *;
@use 'typography-variables' as *;

button,
a {
	&.mat-mdc-button,
	&.mat-mdc-unelevated-button {
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0;
		padding: $spacing-xxs ($spacing-s + $spacing-xxs);
	}
}

.mat-custom-button {
	font-family: $font-helvetica-now-var;
	font-size: $font-body-1;
	font-weight: 800;
}
